import type { HandleClientError } from '@sveltejs/kit'
import * as Sentry from '@sentry/svelte'
import { PUBLIC_ENVIRONMENT } from '$env/static/public'
import { BrowserTracing } from '@sentry/browser'

const environment = PUBLIC_ENVIRONMENT || 'development'

Sentry.init({
	dsn: 'https://0d80b740f0394eb98d76122c23aba3aa@o4504880499195904.ingest.sentry.io/4504880501030912',
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [new BrowserTracing(), new Sentry.Replay()],
	tracesSampleRate: 1.0,
	environment,
})

Sentry.setTag('svelteKit', 'browser')

// This will catch errors in load functions from +page.ts files
export const handleError = (({ error, event }) => {
	Sentry.captureException(error, { contexts: { sveltekit: { event } } })
	let message = 'An error occurred'
	if (error instanceof Error) {
		message = error.message
	}

	return {
		message,
	}
}) satisfies HandleClientError
